<template>
    <header id="main-header">
        <nav class="navbar is-primary is-fixed-top">
            <b-button
                v-if="hasSideMenu"
                id="toggleSidebar"
                type="is-text"
                class="text-decoration-none has-text-white is-shadowless"
                :icon-left="toggleSideMenuIcon"
                data-testid="toggle-sidebar"
                @click="toggleShowMobileSideMenu"
            />
            <div class="navbar-brand">
                <a
                    class="navbar-item"
                    data-testid="logo"
                    :href="$helpers.setUrl('dashboard')"
                >
                    <img
                        :src="logoWhiteLink"
                        :alt="configStore.config.appName"
                    >
                </a>
                <div
                    class="navbar-burger burger has-indicator"
                    data-testid="toggle-nav"
                    @click="toggleNav"
                >
                    <span />
                    <span />
                    <span />
                    <div
                        v-if="nbNotifications"
                        class="indicator shadow"
                    >
                        <p class="has-text-white is-size-7 has-text-centered">
                            {{ nbNotifications > 99 ? '99+' : nbNotifications }}
                        </p>
                    </div>
                </div>
            </div>
            <div
                class="navbar-menu"
                :class="{ 'is-active': showNav }"
            >
                <div class="navbar-start">
                    <a
                        v-if="permissions.canReadOperator"
                        class="navbar-item"
                        :href="$helpers.setUrl('network')"
                        :class="{ 'is-active': activeLink === resources.network }"
                        data-testid="link-header-network"
                    >
                        {{ $t("header.network") }}
                    </a>

                    <a
                        v-if="permissions.canReadLocation"
                        class="navbar-item"
                        :href="$helpers.setUrl('location')"
                        :class="{ 'is-active': activeLink === resources.location }"
                        data-testid="link-header-location"
                    >
                        {{ $t("header.location") }}
                    </a>

                    <a
                        v-if="permissions.canReadEvse"
                        class="navbar-item"
                        :href="$helpers.setUrl('evse')"
                        :class="{ 'is-active': activeLink === resources.evse }"
                        data-testid="link-header-evse"
                    >
                        {{ $t("header.evse") }}
                    </a>

                    <a
                        v-if="permissions.canReadDevice"
                        class="navbar-item"
                        :href="$helpers.setUrl('device')"
                        :class="{ 'is-active': activeLink === resources.device }"
                        data-testid="link-header-device"
                    >
                        {{ $t("header.device") }}
                    </a>

                    <a
                        v-if="permissions.canReadSession"
                        class="navbar-item"
                        :href="$helpers.setUrl('session')"
                        :class="{ 'is-active': activeLink === resources.session }"
                        data-testid="link-header-session"
                    >
                        {{ $t("header.session") }}
                    </a>

                    <a
                        v-if="permissions.canReadStat"
                        class="navbar-item"
                        :href="$helpers.setUrl('stat')"
                        :class="{ 'is-active': activeLink === resources.stat }"
                        data-testid="link-header-stat"
                    >
                        {{ $t("header.stats") }}
                    </a>

                    <a
                        v-if="permissions.canReadContact"
                        class="navbar-item"
                        :href="$helpers.setUrl('contact')"
                        :class="{ 'is-active': activeLink === resources.contact }"
                        data-testid="link-header-contact"
                    >
                        {{ $t("header.contact") }}
                    </a>

                    <div
                        v-if="showFixMenu"
                        :class="{ 'is-submenu-active': fixActiveLink }"
                        class="navbar-item has-dropdown is-hoverable"
                        data-testid="navbar-header-fix"
                    >
                        <a
                            class="navbar-link"
                            :href="$helpers.setUrl('fix')"
                            data-testid="link-header-fix"
                        >
                            {{ $t("header.fix.title") }}
                        </a>
                        <div class="navbar-dropdown is-paddingless-mobile">
                            <a
                                v-if="permissions.canReadFixIssue"
                                class="navbar-item"
                                :href="$helpers.setUrl('fix')"
                                :class="{ 'is-active': activeLink === resources.fix }"
                                data-testid="link-header-fix-dashboard"
                            >
                                {{ $t("header.fix.dashboard") }}
                            </a>
                            <a
                                v-if="permissions.canReadFixIssue"
                                class="navbar-item"
                                :href="$helpers.setUrl('fix', 'history')"
                                :class="{ 'is-active': activeLink === resources.fixHistory }"
                                data-testid="link-header-fix-history"
                            >
                                {{ $t("header.fix.history") }}
                            </a>
                            <a
                                v-if="permissions.canReadFixStat"
                                class="navbar-item"
                                :href="$helpers.setUrl('fix', 'stats')"
                                :class="{ 'is-active': activeLink === resources.fixStats }"
                                data-testid="link-header-fix-stats"
                            >
                                {{ $t("header.fix.stats") }}
                            </a>
                            <a
                                v-if="permissions.canReadFixTag"
                                class="navbar-item"
                                :href="$helpers.setUrl('fix', 'tags')"
                                :class="{ 'is-active': activeLink === resources.fixTags }"
                                data-testid="link-header-fix-tags"
                            >
                                {{ $t("header.fix.tags") }}
                            </a>
                            <div class="mx-1 mt-1">
                                <b-button
                                    v-if="permissions.canCreateFixIssue"
                                    tag="a"
                                    :href="createIssueUrl"
                                    size="is-small"
                                    :icon-left="Icon.add"
                                    type="is-primary"
                                    data-testid="button-header-fix-create"
                                    expanded
                                >
                                    {{ $t(`fix.create.title`) }}
                                </b-button>
                            </div>
                        </div>
                    </div>

                    <a
                        v-if="
                            permissions.canReadRetrocessionGroup
                                && !permissions.canReadRetrocessionGroupLogs
                                && !permissions.canGeneratePdfRetrocessionGroup
                        "
                        class="navbar-item"
                        :href="$helpers.setUrl('retrocession')"
                        :class="{ 'is-active': activeLink === resources.retrocession }"
                        data-testid="link-header-retrocession"
                    >
                        {{ $t("header.retrocessions") }}
                    </a>
                    <div
                        v-else-if="permissions.canReadRetrocessionGroup"
                        class="navbar-item has-dropdown is-hoverable"
                        :class="{ 'is-submenu-active': retrocessionActiveLink }"
                        :data-testid="`navbar-header-retrocession`"
                    >
                        <a
                            class="navbar-link"
                            href="#"
                            data-testid="link-header-retrocession"
                        >
                            {{ $t("header.retrocessions") }}
                        </a>
                        <div class="navbar-dropdown is-paddingless-mobile">
                            <a
                                class="navbar-item"
                                :href="$helpers.setUrl('retrocession')"
                                :class="{ 'is-active': activeLink === resources.retrocession }"
                                data-testid="link-header-retrocession-groups"
                            >
                                {{ $t('header.retrocessionGroups') }}
                            </a>
                            <a
                                v-if="permissions.canReadRetrocessionGroupLogs"
                                class="navbar-item"
                                :href="$helpers.setUrl('retrocessionGroupLog')"
                                :class="{ 'is-active': activeLink === resources.retrocessionGroupLog }"
                                data-testid="link-header-retrocession-logs"
                            >
                                {{ $t('header.retrocessionLogs') }}
                            </a>
                            <a
                                v-if="permissions.canGeneratePdfRetrocessionGroup"
                                class="navbar-item"
                                :href="$helpers.setUrl('retrocessionsPdfGenerator')"
                                :class="{ 'is-active': activeLink === 'retrocessionsPdfGenerator' }"
                                data-testid="link-header-retrocession-pdf"
                            >
                                {{ $t('header.generateRetro') }}
                            </a>
                        </div>
                    </div>

                    <div
                        v-if="showOtherMenu"
                        class="navbar-item has-dropdown is-hoverable"
                        :class="{ 'is-submenu-active': otherActiveLink }"
                    >
                        <a
                            class="navbar-link"
                            href="#"
                            data-testid="navbar-header-others"
                        >
                            {{ $t("header.others") }}
                        </a>
                        <div class="navbar-dropdown is-paddingless-mobile">
                            <a
                                v-if="permissions.canReadTariff"
                                class="navbar-item"
                                :href="$helpers.setUrl('tariff')"
                                :class="{ 'is-active': activeLink === resources.tariff }"
                                data-testid="link-header-tariff"
                            >
                                {{ $t("header.tariff") }}
                            </a>
                            <a
                                v-if="permissions.canReadCdr"
                                class="navbar-item"
                                :href="$helpers.setUrl('cdr')"
                                :class="{ 'is-active': activeLink === 'cdrs' }"
                                data-testid="link-header-cdr"
                            >
                                {{ $t("header.cdr") }}
                            </a>
                            <a
                                v-if="permissions.canImportLocation"
                                class="navbar-item"
                                :href="$helpers.setUrl('location', 'mass-import')"
                                :class="{ 'is-active': activeLink === 'massImport' }"
                                data-testid="link-header-mass-import"
                            >
                                {{ $t("header.locations_mass_import") }}
                            </a>
                            <a
                                v-if="permissions.canGenerateQRcodes"
                                class="navbar-item"
                                :href="$helpers.setUrl('sticker')"
                                :class="{ 'is-active': activeLink === 'generateQRcodes' }"
                                data-testid="link-header-generate-qrcodes"
                            >
                                {{ $t("header.generateQRcodes") }}
                            </a>
                            <a
                                v-if="isAdmin"
                                class="navbar-item"
                                :href="$helpers.setUrl('appSynchroChecker')"
                                :class="{ 'is-active': activeLink === 'appsSynchronizationChecker' }"
                                data-testid="link-header-apps-synchronization-checker"
                            >
                                apps synchronization checker
                            </a>
                            <a
                                v-if="isAdmin"
                                class="navbar-item"
                                :href="$helpers.setUrl('chargeSessionTester')"
                                :class="{ 'is-active': activeLink === 'chargeSessionTester' }"
                                data-testid="link-header-charge-session-tester"
                            >
                                Charge session tester
                            </a>
                            <a
                                v-if="isAdmin"
                                class="navbar-item"
                                :href="$helpers.setUrl('alert')"
                                :class="{ 'is-active': activeLink === 'alert' }"
                                data-testid="link-header-alert"
                            >
                                {{ $t('header.alerts') }}
                            </a>
                            <a
                                v-if="isDevEnv"
                                class="navbar-item"
                                :href="$helpers.setUrl('previewEmail')"
                                data-testid="link-header-preview-email"
                            >
                                <b-tag
                                    type="is-primary"
                                    size="is-small"
                                    class="mr-1"
                                >DEV
                                </b-tag>
                                Preview d'email
                            </a>
                            <a
                                v-if="permissions.canReadTag"
                                class="navbar-item"
                                :href="$helpers.setUrl('tag')"
                                :class="{ 'is-active': activeLink === 'object-tags' }"
                                data-testid="link-header-tags"
                            >
                                {{ $t(`tags.title`) }}
                            </a>
                            <!-- go to /v2/ route without $helpers.setUrl -->
                            <a
                                v-if="isAdmin"
                                class="navbar-item"
                                :href="`/v2/`"
                                :class="{ 'is-active': activeLink === 'object-tags' }"
                                data-testid="link-header-tags"
                            >
                                Fabricants et matériels
                            </a>
                        </div>
                    </div>
                </div>

                <div
                    id="profile"
                    class="navbar-end"
                >
                    <activation-notifications />
                    <notifications v-if="permissions.canReadMaintenance" />
                    <a
                        v-if="permissions.canReadFixIssue"
                        class="navbar-item"
                        :href="fixNotificationLink"
                        data-testid="link-header-fix-notifications"
                    >
                        <span
                            class="tag is-rounded"
                            :class="notifications.ongoing_issues > 0 ? 'is-danger' : 'is-primary'"
                        >{{ notifications.ongoing_issues }}</span>
                        <b-icon
                            icon="tasks"
                            size="is-medium"
                        />
                    </a>
                    <div class="navbar-item has-dropdown is-hoverable">
                        <a class="navbar-link navbar-identity is-arrowless">
                            <span class="circle is-size-7">
                                {{ identity }}
                            </span>
                        </a>
                        <div class="navbar-dropdown is-right is-paddingless">
                            <div class="card">
                                <header class="card-header">
                                    <div class="card-header-title background-light">
                                        <p class="is-size-7">
                                            {{ user.data.email }}

                                            <span v-if="user.rootOperator"> <!-- one role, one permision, one root operator -->
                                                {{ user.rootOperator.full_name }}
                                            </span>

                                            {{ $t('header.role') }}
                                            <b-tooltip
                                                :label="user.role.description"
                                                :active="user.role.description !== null"
                                                class="is-size-7"
                                                position="is-left"
                                                type="is-black"
                                                multilined
                                            >
                                                <span
                                                    class="tag is-primary"
                                                >
                                                    <strong>{{ user.role.name }}</strong>
                                                </span>
                                            </b-tooltip>
                                        </p>
                                    </div>
                                </header>
                                <div class="content">
                                    <a
                                        class="navbar-item"
                                        :href="$helpers.setUrl('dashboard')"
                                        data-testid="link-header-dashboard"
                                    >
                                        {{ $t("header.dashboard") }}
                                    </a>
                                    <a
                                        v-if="permissions.canReadSetting"
                                        class="navbar-item"
                                        :href="$helpers.setUrl('setting')"
                                        data-testid="link-header-settings"
                                    >
                                        {{ $t("header.settings") }}
                                    </a>
                                    <a
                                        class="navbar-item"
                                        :href="$helpers.setUrl('logout')"
                                        data-testid="link-header-logout"
                                    >
                                        {{ $t("header.logout") }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import { mapStores, } from 'pinia'

import helpers from '@/config/helpers.js'
import Icon from '@/config/icon.js'
import { resources, Url, } from '@/config/url.js'

import useConfigStore from '@/state/configStore.js'
import useMainStore from '@/state/mainStore.js'
import useToggleStore from '@/state/toggleStore.js'
import useUserStore from '@/state/userStore.js'

import ActivationNotifications from './ActivationNotifications.vue'
import Notifications from './Notifications.vue'

export default {
    components: {
        ActivationNotifications,
        Notifications,
    },
    props: {
        // If true, display the side menu toggle button
        hasSideMenu: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            Icon,
            isDevEnv: import.meta.env.DEV,
            showNav: false,
            resources,
        }
    },
    computed: {
        ...mapStores(useConfigStore, useUserStore, useToggleStore, useMainStore),
        user () {
            return this.userStore.user
        },
        permissions () {
            return this.userStore.permissions
        },
        isMobile () {
            return helpers.isMobile()
        },
        createIssueUrl () {
            return new Url(resources.fix, 'create').value
        },
        fixNotificationLink () {
            const param = this.notifications.ongoing_issues > 0
                ? 'my-tickets?tab=0'
                : 'my-tickets?tab=1'
            return this.$helpers.setUrl('fix', param)
        },
        activations () {
            return this.configStore.config.activations
        },
        notifications () {
            return this.configStore.config.notifications
        },
        nbNotifications () {
            let total = 0

            Object.keys(this.notifications).map((i) => {
                total += this.notifications[i]
            })

            return total
        },
        /**
         * @returns {string|null}
         */
        rootRefAsEmi3 () {
            if (!this.user?.rootOperator) return null

            return helpers.isValidEmi3(this.user?.rootOperator.ref) // almost only ever true for Park or Move
                ? helpers.formatEmi3(this.user.rootOperator.ref, 'CPO')
                : null
        },
        /**
         * Text display in the supervisor's "avatar"
         *
         * @returns {string|null}
         */
        identity () {
            // Display as an emi3
            if (this.rootRefAsEmi3) return this.rootRefAsEmi3

            let ref = this.user.rootOperator?.ref
            if (ref && ref.length <= 5) return ref

            // for now just take the first ref
            // FIXME Florent 02/03/2022 decide what to do...
            // eslint-disable-next-line prefer-destructuring
            ref = this.user.rootOperatorRefs[0]

            const slugSplited = ref.split('-')
            let initials = ''

            // Build a string with initials (no more than 5)
            // eslint-disable-next-line array-callback-return
            slugSplited.map((slug) => {
                if (initials.length < 5) initials += slug[0].toUpperCase()
            })

            return initials
        },
        activeLink () {
            return this.mainStore.activeLink
        },
        logoWhiteLink () {
            return this.configStore.config.logoUrls.white
        },
        otherActiveLink () {
            return this.activeLink === resources.tariff
        },
        fixActiveLink () {
            return this.activeLink === resources.fix
                || this.activeLink === resources.fixHistory
                || this.activeLink === resources.fixTags
        },
        retrocessionActiveLink () {
            return this.activeLink === resources.retrocession
                || this.activeLink === resources.retrocessionGroupLog
                || this.activeLink === 'retrocessionsPdfGenerator'
        },
        showOtherMenu () {
            // To see "preview email" link
            if (this.isDevEnv) return true

            return this.permissions.canImportLocation
                || this.permissions.canReadTariff
        },
        showFixMenu () {
            return this.permissions.canReadFixIssue
        },
        showMobileSideMenu () {
            return this.toggleStore.showMobileSideMenu
        },
        toggleSideMenuIcon () {
            return this.showMobileSideMenu
                ? 'times'
                : 'ellipsis-v'
        },
        isAdmin () {
            return this.userStore.isAdmin
        },
    },
    mounted () {
        if (this.isMobile)
            this.toggleShowMobileSideMenu()
    },
    methods: {
        // Mobile: display/hide menu sidebar
        toggleShowMobileSideMenu () {
            this.toggleStore.toggleShowMobileSideMenu()

            if (this.showNav) this.showNav = false
        },
        // Mobile: display/hide nav
        toggleNav () {
            this.showNav = !this.showNav

            if (this.showMobileSideMenu) this.toggleStore.toggleShowMobileSideMenu()
        },
    },
}
</script>
